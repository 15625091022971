/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const dashboards = {
  index: lazy(() => import('views/dashboards/Dashboard')),
  userIndex: lazy(() => import('views/dashboards/DashboardUser')),
  financeIndex: lazy(() => import('views/dashboards/DashboardFinance')),
  allTransactions: lazy(() => import('views/dashboards/AllTransactions')),
  allAuditlogs: lazy(() => import('views/dashboards/AllAuditlogs')),
  settings: lazy(() => import('views/dashboards/Settings')),
  // index2: lazy(() => import('views/dashboards/Dashboards')),
};
const application = {
  banners: lazy(() => import('views/application/banners/Banners')),
  add: lazy(() => import('views/application/banners/Banner')),
};

const userManagement = {
  roles: lazy(() => import('views/userManagement/roles/Roles')),
  admins: lazy(() => import('views/userManagement/admins/Admins')),
  customers: lazy(() => import('views/userManagement/customers/Customers')),
  customerDetails: lazy(() => import('views/userManagement/customers/CustomerDetails')),
};
const productsManagement = {
  categories: lazy(() => import('views/productManagement/categories/Categories')),
  category: lazy(() => import('views/productManagement/categories/Category')),
  products: lazy(() => import('views/productManagement/products/Products')),
  product: lazy(() => import('views/productManagement/products/Product')),

};
const salesManagement = {
  roundup: lazy(() => import('views/salesManagement/roundup/Roundup')),
  rewardPoints: lazy(() => import('views/salesManagement/rewardPoints/RewardPoints')),
  affiliations: lazy(() => import('views/salesManagement/affiliations/Affiliations')),
  affiliationsDetails: lazy(() => import('views/salesManagement/affiliations/AffiliationsDetails')),
  OttenCampaign: lazy(() => import('views/salesManagement/affiliations/OttenCampaign')),
  OttenCampaignDetails: lazy(() => import('views/salesManagement/affiliations/CampaignDetails')),
  updateOttenCampaignDetails: lazy(() => import('views/salesManagement/affiliations/UpdateCampaignDetails')),
  updateVcommission: lazy(() => import('views/salesManagement/affiliations/UpdateVcommision')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboard/dashboard-users`,
    },
    {
      path: `${appRoot}/dashboard`,
      component: dashboards.index,
      label: 'Dashboard',
      icon: 'home',
      subs: [
        {
          path: '/dashboard-users', label: 'Dashboard Users', icon: 'user', component: dashboards.userIndex
        },
        {
          path: '/dashboard-finance', label: 'Dashboard Finance', icon: 'dollar', component: dashboards.financeIndex
        },
        {
          path: '/settings', label: 'System Feature', icon: 'gear', component: dashboards.settings, hideInMenu: true
        },
        {
          path: '/all-transactions', label: 'All Transactions', icon: 'gear', component: dashboards.allTransactions, hideInMenu: true
        },
        {
          path: '/all-auditlogs', label: 'All auditlogs', icon: 'gear', component: dashboards.allAuditlogs, hideInMenu: true
        },
      ],
    },
    {
      path: `${appRoot}/application`,
      label: 'Application',
      exact: true,
      icon: 'mobile',
      component: '',
      subs: [
        {
          path: '/banners', label: 'Banners', icon: 'image', component: application.banners,
          // Define additional routes that won't show in menu
          subs: [
            {
              path: '/add',
              label: 'Add Banner',
              component: application.add,
              hideInMenu: true // This will hide it from sidebar/menu
            },

          ]
        },

      ],
    },
    {
      path: `${appRoot}/user-management`,
      label: 'User Management',
      icon: 'user',
      component: '',
      subs: [
        { path: '/roles', label: 'Roles', icon: 'shield', component: userManagement.roles },
        { path: '/admins', label: 'Admins', icon: 'cpu', component: userManagement.admins },
        {
          path: '/customers', label: 'Customres', icon: 'user', component: userManagement.customers,
          subs: [
            {
              path: '/:id',
              label: 'Customer Deatils',
              component: userManagement.customerDetails,
              hideInMenu: true // This will hide it from sidebar/menu
            },

          ]
        },
      ],
    },
    {
      path: `${appRoot}/product-management`,
      label: 'Product Management',
      icon: 'cart',
      component: '',
      subs: [
        {
          path: '/categories', label: 'Categories', icon: 'tag', component: productsManagement.categories,
          subs: [
            {
              path: '/add',
              label: 'Add Category',
              component: productsManagement.category,
              hideInMenu: true // This will hide it from sidebar/menu
            },

          ]
        },
        {
          path: '/products', label: 'Products', icon: 'cart', component: productsManagement.products,
          subs: [
            {
              path: '/add',
              label: 'Add Product',
              component: productsManagement.product,
              hideInMenu: true // This will hide it from sidebar/menu
            },

          ]
        },
      ],
    },
    {
      path: `${appRoot}/sales-management`,
      label: 'Sales & Services',
      icon: 'activity',
      component: '',
      subs: [
        { path: `${appRoot}/dashboard`, label: 'Coupons', icon: 'tag', component: dashboards.index },
        { path: `${appRoot}/dashboard`, label: 'Discounts', icon: 'tag', component: dashboards.index },
        { path: `${appRoot}/dashboard`, label: 'Cashback', icon: 'tag', component: dashboards.index },
        { path: `${appRoot}/dashboard`, label: 'Investments', icon: 'tag', component: dashboards.index },
        { path: `${appRoot}/roundup`, label: 'Roundup', icon: 'abacus', component: salesManagement.roundup },
        { path: `${appRoot}/reward-points`, label: 'Reward Points', icon: 'abacus', component: salesManagement.rewardPoints },

        {
          path: `${appRoot}/affiliations`, label: 'Affiliations', icon: 'tag', component: salesManagement.affiliations,
          subs: [
            {
              path: '/details',
              label: 'Campaign Details',
              component: salesManagement.affiliationsDetails,
              hideInMenu: true // This will hide it from sidebar/menu
            },
          ]
        },
        {
          path: `${appRoot}/otten-campaign`, label: 'Otten Campaign', icon: 'tag', component: salesManagement.OttenCampaign,
          subs: [
            {
              path: '/campaign-details',
              label: 'Otten Campaign Details',
              component: salesManagement.OttenCampaignDetails,
              hideInMenu: true // This will hide it from sidebar/menu
            },
            {
              path: '/campaign-details-update',
              label: 'Update Otten Campaign Details',
              component: salesManagement.updateOttenCampaignDetails,
              hideInMenu: true // This will hide it from sidebar/menu
            },
            {
              path: '/vcommission-campaign-update',
              label: 'Update Otten Campaign Details',
              component: salesManagement.updateVcommission,
              hideInMenu: true // This will hide it from sidebar/menu
            },
          ]
        },
      ],
    },

    {
      path: `${appRoot}/dashboard`,
      label: 'Business Management',
      icon: 'cart',
      component: '',
      subs: [
        { path: `${appRoot}/dashboard`, label: 'Venders', icon: 'tag', component: dashboards.index },
        { path: `${appRoot}/dashboard`, label: 'Organizations', icon: 'tag', component: dashboards.index },
      ],
    },

    {
      path: `${appRoot}/dashboard`,
      label: 'Reporting & Analytics',
      icon: 'cart',
      component: '',
      subs: [
        { path: `${appRoot}/dashboard`, label: 'Transactions', icon: 'tag', component: dashboards.index },
        { path: `${appRoot}/dashboard`, label: 'Reports', icon: 'tag', component: dashboards.index },
      ],
    },








  ],
  sidebarItems: []
};
export default routesAndMenuItems;
