// import axios from "axios";

// export const baseURL = 'https://api.ottenndev.com/dev';

// export const userToken = localStorage.getItem("userToken");

// export const Fetch = async (url, options) => {

//     try {


//         if (!userToken && url !== 'auth/admin/login') {
//             window.location.href = '/login';
//             return;
//         }

//         options.headers = options.headers || {
//             'Content-Type': 'application/json',
//             'Authorization': userToken
//         };


//         const response = await fetch(`${baseURL}/${url}`, options);

//         if (!response.ok) {
//             const errorData = await response.json();
//             throw new Error(errorData.message || "Login failed");
//         }

//         const data = await response.json();
//         return data;
//     } catch (error) {
//         throw new Error(error.message);
//     }
// };


import axios from "axios";

// Define base URLs for different environments
export const BASE_URLS = {
  development: 'https://api.ottenndev.com/dev',
  production: 'https://api.ottenndev.com'
};

// Function to get and set the current environment
export const getEnvironment = () => {
  return localStorage.getItem('selectedEnvironment') || 'production';
};

export const setEnvironment = (env) => {
  localStorage.setItem('selectedEnvironment', env);
};

export const getCurrentBaseURL = () => {
  return BASE_URLS[getEnvironment()];
};

export const userToken = localStorage.getItem("userToken");

export const Fetch = async (url, options) => {
  try {
    // Get the current base URL based on selected environment
    const baseURL = getCurrentBaseURL();

    if (!userToken && url !== 'auth/admin/login') {
      window.location.href = '/login';
      return;
    }

    options.headers = options.headers || {
      'Content-Type': 'application/json',
      'Authorization': userToken
    };

    const response = await fetch(`${baseURL}/${url}`, options);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Request failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    
    if (error.message === "jwt expired") {
      // Redirect to the login page
      window.location.href = "/login";
  } else {
      throw new Error(error.message);
  }
  }
};